import React, { useEffect, useState, useCallback } from "react";
import Cookies from "universal-cookie";
// store utils
import { useStore } from "outstated";
import { AppStore } from "pages/_app";
// next utils
import Script from "next/script";
import dynamic from "next/dynamic";
import { VariantType, useSnackbar } from "notistack";
// utils
import getUserProfile from "lib/getUserProfile";
import getUsersListings from "lib/getUsersListings";
import getUserPermissions from "components/UserPermissions/lib/getUserPermissions";
import getUserRoles from "lib/Admin/getUserRoles";
import getUserRolesForInvite from "lib/Admin/getUserRolesForInvite";
import restrictedList from "components/Authenticator/utils/restrictedList";
// mui
import { useTheme } from "@mui/styles";
import { useRouter } from "next/router";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
// will have to bring this back soon

import ProgressBar from "components/ProgressBar";
import Authenticator from "components/Authenticator";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
// components
import LinearProgressWithLabel from "components/LinearProgressWithLabel";
import { usePostHog } from "posthog-js/react";

const AppBarHeader = dynamic(() => import("components/AppBarHeader"));
// firebase auth
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";

// const SendbirdChat = dynamic(
//   () => import("components/Header/newComponents/SendbirdChat"),
//   {
//     ssr: false,
//   }
// );

// import TalkJSFab from "components/Layout/newComponents/TalkJSFab";
// const TalkJSFab = dynamic(
//   () => import("components/Header/newComponents/TalkJSFab")
//   // {
//   //   ssr: false,
//   // }
// );
// mui
import { set } from "nprogress";

const cookies = new Cookies();

export default function Header({
  children,
  title,
  description,
  setGlobalRestricted,
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  // store
  const {
    setUser,
    layoutVisible,
    setLayoutVisible,
    user,
    userProfile,
    setUserProfile,
    setUserListingsData,
    setIsAdmin,
    surveyFinishedSuccess,
    setSurveyFinishedSuccess,
    firebaseAuth,
    setUserPermissions,
    setUserRoles,
    setUserRolesForInvite,
    freezeWholePageScroll,
    scrollTop,
    setScrollTop,
    snackbarData,
    showProgressBar,
    progressBarCounter,
    isAuthModalOpen,
    setIsAuthModalOpen,
  } = useStore(AppStore);

  const router = useRouter();
  const posthog = usePostHog();

  // const [scrollTop, setScrollTop] = useState(0);
  const [firstClick, setFirstClick] = useState(false);

  // COMMENT OUT AS PART OF OPTIMIZATION WORK
  // const [cookieExists, setCookieExists] = useState(true);
  // const [acceptedCookie, setAcceptedCookie] = useState(false);
  // const [displayUnredeemedTokens, setDisplayUnredeemedTokens] = useState(0);
  // const [showGoldContainerTooltip, setShowGoldContainerTooltip] =
  //   useState(false);
  // const [cookieID, setCookieID] = useState("");
  // const [goldContainerTooltipText, setGoldContainerTooltipText] = useState("");

  // NOTE: commenting out for now
  // no one seemed to use referral modal
  // and it was distraction for majority of new
  // users to make them click to close it
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     // only show sign up if havent seen it in 30 days
  //     const lastTimeShowedReferralModal = localStorage.getItem(
  //       "lastTimeShowedReferralModal"
  //     );
  //     const nowDate = new Date();
  //     const thirtyDaysAgo = new Date(Date.now() - 1000 * (60 * 60 * 24 * 30));

  //     if (
  //       !user &&
  //       new Date(lastTimeShowedReferralModal) < new Date(thirtyDaysAgo)
  //     ) {
  //       setSnackbarOpen(true);

  //       localStorage.setItem("lastTimeShowedReferralModal", nowDate);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    // experiment to see if this delays loading of
    // 3rd party libs so lighthouse doesnt penalize
    // but doesnt override the first click
    setTimeout(function () {
      setFirstClick(true);
    }, 999);

    // set cookies
    // TODO restore this eventually
    // detectCookies();
    // last step is to attach on auth ui state change function

    // on initial load
    // check if user is logged in
    onAuthStateChanged(firebaseAuth, (user) => {
      (async () => {
        if (user) {
          // this is main setUser
          // setUser also happens in the authenticator
          // then many components have useEffect=>[user]
          // to react to the change
          // user change, to trigger useEffect in other spots
          setUser(user);

          if (restrictedList.includes(user.email)) {
            setGlobalRestricted(true);
          }
        } else {
          setUser(false);
        }
      })();
    })();
  }, []);

  useEffect(() => {
    if (snackbarData.message) {
      // const snackbarData = {
      //   message: "Unread",
      //   options: {
      //     variant: "warning",
      //     anchorOrigin: {
      //       vertical: "top",
      //       horizontal: "center",
      //     },
      //   },
      // };
      // NOTE: replacements for global <Snackbar> component
      // allows for stacking of snackbar messages
      enqueueSnackbar(...Object.values(snackbarData));
      // IDEA: can read autoHideDuration from options
      // and trigger, clean up message after and in mean time, if users
      // click on the message, there could be a scroll action
    }
  }, [snackbarData]);

  useEffect(() => {
    // layout should be visible on route changes
    // setLayoutVisible(true);
    // make sure on navigation to always scroll to the top of the page
    window.scrollTo(0, 0);
    // dont show authenticator on route change
    // unless its /login route

    // if its survey route, don't flip
    // back to having layout visible
    if (!router.pathname.includes("survey/")) {
      setLayoutVisible(true);
    }
    if (surveyFinishedSuccess) {
      // on route change, make sure survey success
      // messaget gets hidden
      setSurveyFinishedSuccess(false);
    }
  }, [router]);

  // if user changes
  // sign in and sign out sensing
  // all in one place
  useEffect(() => {
    // if (user && Object.keys(userProfile).length === 0) {
    //   // TODO
    //   // log out user with expired tokens
    //   // if (currentLinuxTimeStamp > user.stsTokenManager.expirationTime) {
    //   //   // log user out, their id token expired
    //   //   setUser(false);
    //   //   log out
    //   // }

    //   const userProfile = acquireUserProfile(user, setUserProfile);

    //   // updateUserProfileTrackingData(
    //   //   user,
    //   //   setRedeemedTokens,
    //   //   setEmail,
    //   //   userProfile
    //   // ); // check manually the first time because we won't get a Hub event
    // }

    if (user) {
      // on login hide auth modal
      setIsAuthModalOpen(false);

      const isAdmin = process.env.ADMIN_UIDS.includes(user?.uid);
      if (isAdmin) {
        setIsAdmin(isAdmin);
      }

      if (user?.reloadUserInfo?.customAttributes) {
        (async () => {
          const userPermissions = await getUserPermissions(user);
          setUserPermissions(userPermissions);

          if (userPermissions.Role && userPermissions.Organization === "OM") {
            const userRoles = await getUserRoles(user);
            setUserRoles(userRoles.UserRoles);
            const userRolesForInvite = await getUserRolesForInvite(user);
            setUserRolesForInvite(userRolesForInvite);
          } else if (userPermissions.Role) {
            const userRoles = await getUserRoles(user);
            setUserRoles(userRoles.UserRoles);
            setUserRolesForInvite([]);
          } else {
            setUserRoles([]);
            setUserRolesForInvite([]);
          }
        })();
      }

      if (Object.keys(userProfile).length === 0) {
        (async () => {
          const userProfile = await getUserProfile(user);

          setUserProfile(userProfile);
        })();
      }

      // TODO this might not be necessary
      // in few cases like /listing, when user signs in at listing
      // or uses subscribe/deal-flow survey, when we log in there using signIn func
      // that function can be made to return the user obj and continue there instead of
      // triggers here in layout
      // user just signed in since userProfile is not populated

      // user just signed in
      // next behavior is dictated by
      // where they are coming from
    }
  }, [user]);

  useEffect(() => {
    // // Check if user has listings
    if (userProfile && Object.keys(userProfile).length > 0 && user) {
      getUsersListings(user, setUserListingsData);
      // also link to posthog
      posthog.identify(userProfile.UID, {
        email: userProfile.Email,
        name: `${userProfile?.FirstName} ${userProfile?.LastName}`,
      });
    }
  }, [user, userProfile]);

  const onScroll = useCallback(
    (event) => {
      // document.body doesn't show exact offset y for me
      setScrollTop(1);

      window.removeEventListener("scroll", onScroll, { passive: true });
    },
    [setScrollTop]
  );

  useEffect(() => {
    //add eventlistener to window
    window.addEventListener("scroll", onScroll, { passive: true });

    // remove event on unmount to prevent a memory leak with the cleanup
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true });
    };
  }, []);

  return (
    <>
      {/* <SendbirdChat user={user} userProfile={userProfile} /> */}
      {layoutVisible && <AppBarHeader key={"appbarheader"} />}

      {showProgressBar && (
        <Box
          sx={{
            width: "90%",
            zIndex: "999999",
            position: "fixed",
            top: "7rem",
            right: "5%",
            backgroundColor: "white !important",
            border: `${theme.spacing(0.25)} solid ${
              theme.palette.primary.main
            }`,
            borderRadius: theme.spacing(0.5),
            boxShadow: "4px 5px 7px -5px black",
            padding: theme.spacing(2),
            width: "333px",
            "& .bg-primary": {
              backgroundColor: `${process.env.lightGreen} !important`,
            },
            [theme.breakpoints.down("md")]: {
              width: "90%",
            },
          }}
        >
          <LinearProgressWithLabel
            theme={theme}
            // customColor={"white"}
            value={progressBarCounter || 0}
          />
        </Box>
      )}

      <Modal
        open={isAuthModalOpen}
        onClose={() => {
          setIsAuthModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: theme.spacing(1),
            borderColor: theme.palette.primary.light,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDiretion: "row",
              marginBottom: theme.spacing(1.5),
            }}
          >
            <Typography
              sx={{
                margin: "auto 0",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Please login or sign up to do that
            </Typography>
            <IconButton
              title={`Close suggestion modal`}
              color="warning"
              style={{
                float: "right",
                fontSize: "16px",
                padding: "",
                margin: "auto 0 auto auto ",
                padding: 0,
              }}
              onClick={() => {
                setIsAuthModalOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Authenticator showTitle={false} />
        </Box>
      </Modal>
      {/* https://nextjs.org/docs/basic-features/script */}
      {/* UPDATE (07/13/2023): no more chatJS for now, comment out at end of July 20th */}
      {/* Before vacay */}
      {/* <TalkJSFab user={user} userProfile={userProfile} /> */}

      <style jsx global>{`
        html,
        body {
          height: 100%;
          width: 100%;
          padding: 0;
          margin: 0;
          font-family: Open Sans;
          overflow: ${freezeWholePageScroll ? "hidden" : "visible"};
          padding-bottom: constant(safe-area-inset-bottom);
        }
        header {
          height: auto;
        }
        footer {
          height: 25px;
          text-align: center;
        }
        #nprogress .bar {
          background: linear-gradient(
            45deg,
            ${process.env.deeppurple},
            3%,
            ${process.env.lightGreen}
          );
          height: 10px !important;
        }
        #nprogress .peg {
          box-shadow: 0 0 10px ${process.env.lightGreen}, 0 0 5px #ffbb00;
        }
        #nprogress .spinner-icon {
          border-top-color: ${process.env.lightGreen};
          border-left-color: ${process.env.lightGreen};
        }
        .sendbird-modal-root {
          z-index: 10;
        }
        #navPopper {
          z-index: 1000;
        }
        #chatbase-bubble-button {
          bottom: 1.4rem !important;
        }
      `}</style>

      {/* <!-- LiveSession.io code --> */}
      {/* {(scrollTop !== 0 || firstClick) && (
        <Script
          // strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            !function(w, d, t, u) {
              if (w.__ls) return; var f = w.__ls = function() { f.push ? f.push.apply(f, arguments) : f.store.push(arguments)};
              if (!w.__ls) w.__ls = f; f.store = []; f.v = "1.0";
      
              var ls = d.createElement(t); ls.async = true; ls.src = u;
              var s = d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(ls, s);
          }(window, document, 'script', ('https:' == window.location.protocol ? 'https://' : 'http://') + 'cdn.livesession.io/track.js');
      
          __ls("init", "96e8f4f5.b44f0874", { keystrokes: false });
          __ls("newPageView");
          
          //TODO later use This helps you to check for individual user sessions by name , email or custom property. 
          // __ls("identify", { 
          //   name: "Adam McKenzie", 
          //   email: "adam@goodstuffinc.com", 
          //})
            `,
          }}
        />
      )} */}
      {/* <!-- FullStory code --> */}
      {/* TODO restore and figure out why it made links not clickable on first try*/}
      {/* {(scrollTop !== 0 || firstClick) && (
        <Script
          // strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            window['_fs_namespace'] = "FullStory999";
            window['_fs_debug'] = false;
            window['_fs_host'] = 'fullstory.com';
            window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
            window['_fs_org'] = '15XB0G';
            (function(m,n,e,t,l,o,g,y){
                if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                g.anonymize=function(){g.identify(!!0)};
                g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                g.log = function(a,b){g("log",[a,b])};
                g.consent=function(a){g("consent",!arguments.length||a)};
                g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                g.clearUserCookie=function(){};
                g.setVars=function(n, p){g('setVars',[n,p]);};
                g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                g._v="1.3.0";
            })(window,document,window['_fs_namespace'],'script','user');
            `,
          }}
        />
      )} */}
      {/* <Script
        strategy="lazyOnload"
        src="https://www.googleoptimize.com/optimize.js?id=OPT-NGS7BJ9"
      /> */}

      {/* Google Analytics */}
      {/* Userway */}
      {(scrollTop !== 0 || firstClick) && [
        <Script
          key={"userway_script"}
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
     (function(d){var s = d.createElement("script");s.setAttribute("data-account", "h5wCO6XmKb");s.setAttribute("src", "https://accessibilityserver.org/widget.js");(d.body || d.head).appendChild(s);})(document)
       `,
          }}
        />,
        <noscript key={"noscript_script"}>
          Please ensure Javascript is enabled for purposes of{" "}
          <a href="https://accessibilityserver.org">website accessibility</a>
        </noscript>,
      ]}

      {/* FB pixel */}
      {/* <!-- Meta Pixel Code --> */}
      {/* {scrollTop !== 0 && [ */}
      {/* UPDATE: 03/26/2024, not really using FB for anything right now, so optimizing this out for load speed */}
      {/* <Script
        key={"fb_script"}
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
           !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '825944035455237');
            fbq('track', 'PageView');
            `,
        }}
      /> */}
      {/* UPDATE: 03/26/2024, not really using FB for anything right now, so optimizing this out for load speed */}
      {/* <noscript key={"noscript_script_1"}>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=825944035455237&ev=PageView&noscript=1"
        />
      </noscript> */}
      {/* ]} */}
      {/* <!-- End Meta Pixel Code --> */}
      {/* {scrollTop !== 0 && ( // not sure what this is
        <Script
          strategy="lazyOnload"
          crossOrigin="anonymous"
          nonce="wONPyMXW"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v8.0&appId=249572643132113&autoLogAppEvents=1"
        />
      )} */}
    </>
  );
}
